import request from "@/core/services/axios";
export function getRoleSearchParams(params) {
  // 获取筛选参数权限
  return request({
    url: `/cms/schoolgradeclass/userroleinfo`,
    method: "get",
    params: params,
  });
}
export function getstatinfo(params) {
  // 获取统计和科目信息
  return request({
    url: `/report/stat/getstatinfo`,
    method: `get`,
    params: params,
  });
}
export function getstatunionuserinfo(params) {
  // 获取当前用户在指定联考统计的信息
  return request({
    url: `/report/stat/getstatunionuserinfo`,
    method: `get`,
    params: params,
  });
}

export function getstatclassoption(params) {
  // 获取统计的班级下拉列表
  //eleccateSubjectId 8 物理类  4历史类
  /**@param{Number} isNeedTeach 1 教学班 0 行政班 **/
  if (params.viewSchoolId) {
    return request({
      url: `/report/stat/getstatunionclassoption`,
      method: "get",
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  }
  return request({
    url: `/report/stat/getstatclassoption/v2`,
    method: "get",
    params: params,
  });
}
export function getstatonlineoption(params) {
  if (params.viewSchoolId) {
    return request({
      url: `/report/stat/getstatonlinesetting`,
      method: "get",
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  }
  // 获取统计的上线下拉列表
  return request({
    url: `/report/stat/getstatonlineoption`,
    method: "get",
    params: params,
  });
}
export function getstatsubjectoption(params) {
  // 获取考试的科目列表
  // id 统计id
  // classNum 指定班级，获取该班级下的科目列表
  // hasTotal 是否包含总分 0-不包含 1-包含
  // requestPage 1-校级报告 2-教研报告 3-班主任报告 4-任课教师报告 5-试卷讲评 6-学生报告
  if (params.viewSchoolId || params.type == "union") {
    return request({
      url: `/report/stat/getstatunionsubjectoption`,
      method: "get",
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  }
  return request({
    url: `/report/stat/getstatsubjectoption/v2`,
    method: "get",
    params: params,
  });
}
export function getstatcomparelist(params) {
  // 根据statId查询考试列表
  return request({
    url: `/report/stat/getstatstudentwavecomparelist`,
    method: "post",
    data: params,
  });
}
export function getsrlearningtrackstatlist(params) {
  // 根据statId查询考试列表
  if (params.viewSchoolId) {
    return request({
      url: `/report/statunionstudentreport/getsrlearningtrackstatlist`,
      method: "post",
      data: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  }
  return request({
    url: `/report/statstudentreport/getsrlearningtrackstatlist`,
    method: "post",
    data: params,
  });
}

export function getstatratiooption(params) {
  // 获取统计的比率下拉列表
  return request({
    url: `/report/stat/getstatratiooption`,
    method: `get`,
    params: params,
  });
}
export function getstattopsectionoption(params) {
  // 获取统计的前N名下拉列表
  return request({
    url: `/report/stat/getstattopsectionoption`,
    method: `get`,
    params: params,
  });
}
export function getstatapsubjectoption(params) {
  // 获取统计的赋分科目下拉列表
  return request({
    url: `/report/stat/getstatapsubjectoption`,
    method: `get`,
    params: params,
  });
}
export function getstatschooloption(params) {
  // 获取联考统计的学校列表
  return request({
    url: `/report/stat/getstatschooloption`,
    method: `get`,
    params: params,
  });
}
export function getstatdevelopanalysiscomparelist(params) {
  if (params.viewSchoolId) {
    return request({
      url: `/report/stat/getstatuniondevelopanalysiscomparelist`,
      method: "post",
      data: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  } else {
    // 对比考试接口v2
    return request({
      url: `/report/stat/getstatdevelopanalysiscomparelist`,
      method: `post`,
      data: params,
    });
  }
}
export function getstateleccomboption(params) {
  // 获取统计选考组合
  // Integer id,   统计ID
  // Integer hasAll,   全组合
  // Integer hasPhysicsCate,   物理类
  // Integer hasHistoryCate,   历史类
  // Integer subjectId,   科目ID
  // String classNum   班级
  // 比如 年级榜-总分（选考组合）  这个页面请求选考组合时需要传：
  // statId
  // hasPhysicsCate=1   需要“物理类”这个大类
  // hasHistoryCate=1  需要“历史类”这个大类
  return request({
    url: `/report/stat/getstateleccomboption`,
    method: `get`,
    params: params,
  });
}
export function getdefaultstatparam() {
  // 获取统计模板默认参数
  return request({
    url: `/cms/school/getdefaultstatparam`,
    method: `get`,
  });
}
export function getsrlearningtrackstatlistV2(params) {
  // 学生报告-学情跟踪-统计列表搜索
  return request({
    url: `/report/statnewgaokaostudentreport/getsrlearningtrackstatlist`,
    method: `post`,
    data: params,
  });
}
export function getnewgaokaotopinfo(params) {
  // 获取新高考-统计报告详情页顶部数据
  return request({
    url: `/report/statreportinfo/getnewgaokaotopinfo`,
    method: `get`,
    params: params,
  });
}
export function getstateleccomboptionforreport(params) {
  // 获取统计选考组合（报告用）
  return request({
    url: `/report/stat/getstateleccomboptionforreport`,
    method: `get`,
    params: params,
  });
}
export function getstateleccateoption(params) {
  // 获取统计的选考类别下拉列表
  //   id 统计ID
  // classNum [非必传值]指定班级，获取该班级下的选考组合列表
  // subjectId [非必传值]指定科目，获取包含该科目的选考组合列表
  // hasAll [非必传值]是否包含“全组合” 0-不包含 1-包含
  // hasPhysicsCate [非必传值]是否包含“物理类”选考类别 0-不包含 1-包含
  // hasHistoryCate [非必传值]是否包含“历史类”选考类别 0-不包含 1-包含
  if (params.viewSchoolId) {
    return request({
      url: "/report/stat/getstatunioneleccateoption",
      method: `get`,
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  }
  return request({
    url: `/report/stat/getstateleccateoption`,
    method: `get`,
    params: params,
  });
}
export function getstateleccomboptionV2(params) {
  // 获取统计的选考组合下拉列表V2
  //   id 统计ID
  // id 统计ID
  // classNum [非必传值]指定班级，获取该班级下的选考组合列表
  // subjectId [非必传值]指定科目，获取包含该科目的选考组合列表
  // eleccateId 选考类别ID 0-全部类别 98-物理类 99-历史类
  // hasCate [非必传值]是否包含类别（“物理类”、“历史类”） 0-不包含 1-包含
  if (params.viewSchoolId) {
    return request({
      url: "/report/stat/getstatunioneleccomboption",
      method: `get`,
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  }
  return request({
    url: `/report/stat/getstateleccomboption/v2`,
    method: `get`,
    params: params,
  });
}
export function getstatuniondevelopanalysiscomparelist(params) {
  // 联考报告-发展性分析-对比考试列表
  return request({
    url: `/report/stat/getstatuniondevelopanalysiscomparelist`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
export function getstatschoolcomparelist(params) {
  // 统计设置对比考试列表
  return request({
    url: `/report/stat/getstatschoolcomparelist`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
}
