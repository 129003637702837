<template>
  <div class="filter-component">
    <div
      v-for="(item, index) in options"
      :key="index"
      class="filter-component-item"
    >
      <span v-if="item.label">{{ item.label }}</span>
      <el-select
        v-if="item.type === 'select'"
        v-model="search[item.field]"
        :style="{ maxWidth: `${item.width ? item.width : 100}px` }"
        :placeholder="item.placeholder"
        clearable
        @change="(value) => handleChange(value, item)"
      >
        <el-option
          v-for="el in item.options"
          :key="el[item.key || 'value']"
          :label="el[item.labelKey || 'label']"
          :value="el[item.valueKey || 'value']"
        >
        </el-option>
      </el-select>
      <el-input
        v-if="item.type === 'input'"
        v-model="search[item.field]"
        :placeholder="item.placeholder ? item.placeholder : '搜索'"
        suffix-icon="el-icon-search"
        style="width: 110px"
        clearable
        @input="(value) => handleChange(value, item)"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterComponent",
  props: {
    options: {
      default: () => [],
      type: Array,
    },
    formatterData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      search: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.search = { ...this.formatterData };
    });
  },
  methods: {
    handleChange(value, item) {
      if (item.reset && this.search[item.reset]) {
        this.$delete(this.search, item.reset);
      }
      this.$emit("change", this.search);
      if (item.handler) {
        item.handler(value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-component {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &-item {
    margin-right: 18px;
    @media screen and (max-width: 1450px) {
      margin-bottom: 20px;
    }
    span {
      font-size: 14px;
      line-height: 1;
      color: #080a09;
      margin-right: 8px;
    }
    ::v-deep .el-select {
      height: 32px;
    }
  }
}
::v-deep .el-input__inner {
  padding: 0 10px;
}
</style>
