<template>
  <div class="reportStat">
    <div class="top-dashboard">
      <div class="top-dashboard-item">
        <div class="icon">
          <img src="@/static/academic/examTotal.png" alt="" />
        </div>
        <div class="data">
          <div class="num">{{ statisticsInfo.examTotalCount }}</div>
          <div class="title">考试总数</div>
        </div>
      </div>
      <div class="top-dashboard-item">
        <div class="icon">
          <img src="@/static/academic/generalExam.png" alt="" />
        </div>
        <div class="data">
          <div class="num">{{ statisticsInfo.unionExamCount }}</div>
          <div class="title">联考总数</div>
        </div>
      </div>
      <div class="top-dashboard-item">
        <div class="icon">
          <img src="@/static/academic/schoolExam.png" alt="" />
        </div>
        <div class="data">
          <div class="num">{{ statisticsInfo.schoolExamCount }}</div>
          <div class="title">校试总数</div>
        </div>
      </div>
      <div class="top-dashboard-item">
        <div class="icon">
          <img src="@/static/academic/newExam.png" alt="" />
        </div>
        <div class="data">
          <div class="num">{{ statisticsInfo.newExamCount }}</div>
          <div class="title">新高考总数</div>
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      class="card content"
      style="min-height: 45vh"
    >
      <filter-component
        ref="filter"
        :key="searchKey"
        :options="filterOptions"
        :formatter-data="search"
        @change="getFilterValue"
      />
      <div v-if="list.length" class="timeLine">
        <div v-for="(item, index) in list" :key="index" class="timeLine-item">
          <div class="timeLine-item-content">
            <div class="time">{{ item.examDate }}</div>
            <div class="dotted"></div>
            <div class="msg">
              <div class="title">
                {{ item.name }}
              </div>
              <div class="operation">
                <div class="tags">
                  <div v-if="item.ruleId > 0" class="tag newExam">新高考</div>
                  <div
                    :class="[
                      item.securityLevel === 1 ? 'security1' : 'security2',
                    ]"
                  >
                    {{ getSecurityName(item.securityLevel) }}
                  </div>
                  <div class="tag time">
                    <span v-if="item.level == 2">初</span
                    ><span v-if="item.level == 3">高</span>{{ item.year }}级
                  </div>
                  <div
                    :class="[
                      'tag',
                      item.examExamType == 99 ? 'other' : 'general',
                    ]"
                  >
                    {{ getExamType(item.examExamType) }}
                  </div>
                  <div v-if="item.type === 1" class="tag examType1">
                    考试统计
                  </div>
                  <div v-if="item.type === 2" class="tag examType2">
                    单科统计
                  </div>
                  <div v-if="item.type === 3" class="tag examType3">
                    自定义统计
                  </div>
                </div>
                <div class="btn">
                  <el-button
                    v-if="isShowReportSheet(item)"
                    type="primary"
                    plain
                    @click="reportForm('/academic/once', item)"
                    >查看报表</el-button
                  >
                  <el-button
                    v-if="validateReport(item)"
                    type="primary"
                    plain
                    @click="reportDetail(item)"
                    >查看报告
                  </el-button>
                </div>
              </div>
              <div class="subjects">
                {{ item.subjectStr }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <no-data v-else message="暂无数据" />
      <div class="page-list">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev,pager, next, jumper"
          :total="total"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <template v-if="title === '筛选联考学校'">
        <div class="schools">
          <a-space style="margin-bottom: 18px">
            <a-input v-model="schoolName" placeholder="查询学校名称">
              <a-icon slot="suffix" type="search" />
            </a-input>
            <a-button type="primary" @click="schoolNameInput">搜索</a-button>
          </a-space>
          <div v-if="originSchoolList.length" class="schoolList">
            <div
              v-for="(item, index) in schoolList"
              :key="index"
              class="school"
              @click="goDetail(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <no-data v-else message="当前账号没有查看当前联考的权限"></no-data>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getstatreportstatisticsinfo,
  reportpage,
} from "@/core/api/academic/report";

import filterComponent from "./components/filter.vue";
import { getGradeList } from "@/core/api/school/schoolgradeclass";
import { examModeOptions } from "@/core/util/constdata";
import {
  levelOptions,
  examTypeOptions,
  examSecurityLevelOptions,
} from "@/core/util/constdata";
import {
  getRoleSearchParams,
  getstatschooloption,
} from "@/core/api/academic/common";
import { getSubjectList } from "@/core/util/util";
import noData from "@/components/noData";
import { getStore } from "@/core/util/store";
import { mapState } from "vuex";
// import { levelOptions } from "@/core/util/constdata";
export default {
  // 统计报告
  name: "ReportStat",
  components: {
    filterComponent,
    noData,
  },
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let years = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    const yearList = years.reverse();
    let subjectList = getSubjectList();
    return {
      currentStat: {},
      userInfo: {},
      dialogVisible: false,
      schoolName: undefined,
      schoolList: [],
      originSchoolList: [],
      // roleId: null,
      levelOptions: levelOptions(),
      filterOptions: [
        {
          label: "",
          field: "roleType",
          type: "select",
          placeholder: "全部角色",
          options: [],
          labelKey: "teacherRoleName",
          valueKey: "type",
          reset: "examLevel",
          handler: (value) => {
            this.setLevelOptions(value);
          },
        },
        {
          label: "",
          field: "examLevel",
          placeholder: "全部学段",
          options: [],
          type: "select",
        },

        {
          placeholder: "全部年份",
          field: "examYear",
          options: [...yearList],
          type: "select",
          labelKey: "label",
          valueKey: "value",
        },
        {
          placeholder: "全部模式",
          field: "examMode",
          options: examModeOptions,
          type: "select",
        },
        {
          placeholder: "全部类型",
          field: "examType",
          options: examTypeOptions,
          type: "select",
        },
        {
          placeholder: "全部科目",
          field: "examSubjectId",
          options: [...subjectList],
          type: "select",
          labelKey: "subjectName",
          valueKey: "subjectId",
        },
        {
          placeholder: "全部时间",
          field: "examDateYear",
          options: [...yearList],
          type: "select",
          labelKey: "label",
          valueKey: "value",
        },

        // {
        //   label: "考试状态：",
        //   field: "statusStatistic",
        //   options: [
        //     {
        //       label: "未统计",
        //       value: 0,
        //     },
        //     {
        //       label: "已统计",
        //       value: 1,
        //     },
        //   ],
        //   type: "select",
        // },
        {
          field: "examName",
          type: "input",
        },
      ],
      yearMiddleOptions: [], //初中入学年份
      yearHignOptions: [], //高中入学年份
      search: {
        current: 1,
        size: 50,
      },
      loading: false,
      searchKey: Math.random(),
      total: 0,
      list: [],
      statisticsInfo: {},
      title: "",
      allLevelList: [],
      teacherName: {
        1: "任课老师",
        2: "班主任",
        3: "备课组长",
        4: "年级组长",
        5: "校领导",
        6: "教务老师",
        // 11: "联考用户",
        // 12: "联考阅卷老师",
      },
    };
  },
  computed: {
    ...mapState({
      roleId: (state) => state.report.roleId,
    }),
  },
  async mounted() {
    const searchObj = sessionStorage.getItem(this.$route.path);
    const res = await getRoleSearchParams();
    const { levels } = res.data.data;
    this.allLevelList = this.levelOptions.filter((item) =>
      levels.includes(Number(item.value))
    );
    if (searchObj) {
      this.search = { ...JSON.parse(searchObj) };
      this.searchKey = Math.random();
    }
    const userInfo = JSON.parse(localStorage.getItem("jzjx-userInfo")).content;
    if (this.roleId == 6 || userInfo.schoolType == 2) {
      this.filterOptions.splice(4, 1);
    }
    const result = this.showRoleSelect(userInfo.teacherRoleList);
    if (!result) {
      this.filterOptions = this.filterOptions.filter(
        (item) => item.field !== "roleType"
      );
      if (this.search.roleType) {
        this.$delete(this.search, "roleType");
      }
    } else {
      const roleTypes = [
        ...new Set(userInfo.teacherRoleList.map((item) => item.type)),
      ].filter((item) => [1, 2, 3, 4, 5].includes(item));
      const schoolId = JSON.parse(
        localStorage.getItem("jzjx-school_id")
      ).content;
      if (schoolId == 20313) {
        this.search.roleType = Math.min(...roleTypes);
        this.$refs.filter.search.roleType = this.search.roleType;
      }

      const teacherRoleTypeList = roleTypes.map((item) => {
        const data = userInfo.teacherRoleList.filter((it) => it.type == item);
        const levels = data.map((it) => it.level) || [];
        return {
          teacherRoleName: this.teacherName[item],
          type: item,
          level: [...new Set(levels)],
        };
      });
      // 1-任课教师 2-班主任 3-备课组长 4-年级组长 5-校领导
      this.roleList = teacherRoleTypeList;
      const roleTypeIndex = this.filterOptions.findIndex(
        (item) => item.field == "roleType"
      );
      if (roleTypeIndex !== null && roleTypeIndex !== undefined) {
        this.filterOptions[roleTypeIndex].options = this.roleList;
      }
      this.setLevelOptions(this.search.roleType);
    }

    this.getStatisticsInfo();
    this.getData();
  },

  methods: {
    showRoleSelect(teacherRoleList) {
      const levelIndex = this.filterOptions.findIndex(
        (item) => item.field == "examLevel"
      );
      if (!teacherRoleList || teacherRoleList.length === 0) {
        this.filterOptions[levelIndex].options = [
          {
            value: "1",
            label: "小学",
          },
          {
            value: "2",
            label: "初中",
          },
          {
            value: "3",
            label: "高中",
          },
          {
            value: "4",
            label: "大学",
          },
        ];
        return false;
      }
      const result = teacherRoleList.filter((item) =>
        [1, 2, 3, 4, 5].includes(item.type)
      );
      if (!result.length) {
        this.filterOptions[levelIndex].options = [
          {
            value: "1",
            label: "小学",
          },
          {
            value: "2",
            label: "初中",
          },
          {
            value: "3",
            label: "高中",
          },
          {
            value: "4",
            label: "大学",
          },
        ];
        return false;
      }
      return true;
    },
    setLevelOptions(type) {
      const levelIndex = this.filterOptions.findIndex(
        (item) => item.field == "examLevel"
      );
      if ([1, 2, 3, 4, 5].includes(type)) {
        const levelEnmu = {
          1: "小学",
          2: "初中",
          3: "高中",
          4: "大学",
        };
        const data = this.roleList.find((item) => item.type == type);
        const levels = data.level.map((item) => ({
          label: levelEnmu[item],
          value: item,
        }));

        this.filterOptions[levelIndex].options = [...levels];
      } else {
        this.filterOptions[levelIndex].options = [...this.allLevelList];
      }
    },
    validateReport(item) {
      if (item.examType == 1) {
        return true;
      }
      let { schoolVersion } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      if (schoolVersion == 1) {
        return false;
      }
      // if (item.examType == 1) {
      //   return item.isUnionSubjectUser == 0;
      // }
      return true;
    },
    isShowReportSheet(item) {
      if (this.roleId !== 4 && item.statusView == 1) {
        if (item.examType == 1) {
          let userInfo = getStore({
            name: "userInfo",
          });
          return userInfo.schoolType != 1;
        }
        return true;
      } else {
        return false;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    goDetail(item) {
      this.$store
        .dispatch("report/getRoleTypeArr", this.currentStat.id)
        .then((res) => {
          this.routerBase64To("/academic/reportStat/reportDetail", {
            ...this.currentStat,
            schoolId: item.id,
            schoolName: item.name,
          });
          sessionStorage.setItem("accountRole", JSON.stringify(res));
        });
    },
    routerBase64To(routerName, item) {
      this.$router.push({
        name: routerName,
        query: {
          id: item.id,
          level: item.level,
          examId: item.examId,
          ruleId: item.ruleId,
          schoolId: item.schoolId,
          schoolName: item.schoolName,
          isSchoolDisplayReportStudentRank:
            item.isSchoolDisplayReportStudentRank,
          scoreType: item.scoreType,
          examType: item.examType,
        },
      });
    },
    schoolNameInput() {
      if (this.schoolName) {
        this.schoolList = this.originSchoolList.filter(
          (item) => item.name.indexOf(this.schoolName) != -1
        );
      } else {
        this.schoolList = [...this.originSchoolList];
      }
    },
    getSecurityName(level) {
      if (level) {
        const data = examSecurityLevelOptions.find(
          (item) => item.value === level
        );
        if (data) {
          return data.label;
        }
      }
    },

    async getStatisticsInfo() {
      const res = await getstatreportstatisticsinfo();
      const { data } = res.data;
      this.statisticsInfo = { ...data };
      this.$nextTick(() => {
        this.userInfo = JSON.parse(localStorage.getItem("jzjx-userInfo"));
        // this.roleId = this.userInfo.content.roleId;
      });
    },
    reportForm(routerName, item) {
      if (item.examType === 1) {
        const { href } = this.$router.resolve({
          path: "/union/sheet",
          query: {
            id: item.id,
            name: item.name,
            examType: item.examType,
            type: item.type,
            level: item.level,
            isUnionSubjectUser: item.isUnionSubjectUser,
            scoreType: item.scoreType,
            examDate: item.examDate,
            ruleId: item.ruleId,
            examId: item.examId,
            year: item.year,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      } else {
        this.$store.dispatch("report/getRoleTypeArr", item.id).then((res) => {
          sessionStorage.setItem("accountRole", JSON.stringify(res));
          this.$router.push({
            name: routerName,
            query: {
              statId: item.id,
              isUnionSubjectUser: item.isUnionSubjectUser,
              level: item.level,
              ruleId: item.ruleId,
              examId: item.examId,
            },
          });
        });
      }
    },
    routerTo(routerName, item) {
      if (item) {
        let params = {
          id: item.id,
          level: item.level,
          examId: item.examId,
          ruleId: item.ruleId,
          examType: item.examType,
          isSchoolDisplayReportStudentRank:
            item.isSchoolDisplayReportStudentRank,

          schoolId:
            item.examType == 1
              ? JSON.parse(localStorage.getItem("jzjx-school_id")).content
              : null,
        };
        this.$router.push({
          name: routerName,
          query: { ...params },
        });
      } else {
        this.$router.push({
          name: routerName,
        });
      }
    },
    async reportDetail(item) {
      if (item.examType == 1) {
        let { schoolType } = JSON.parse(
          localStorage.getItem("jzjx-userInfo")
        ).content;
        if (
          this.roleId == 6 ||
          ([1, 5].includes(this.roleId) && schoolType == 2)
        ) {
          this.currentStat = { ...item };
          this.title = "筛选联考学校";
          this.dialogVisible = true;
          const res = await getstatschooloption({ id: item.id });
          this.schoolList = res.data.data;
          this.originSchoolList = res.data.data;
          this.dialogVisible = true;
        } else if (this.roleId == 4) {
          const isNewExam = item.scoreType == 1 && item.ruleId > 0;
          const path = isNewExam
            ? "/academic/new-exam/student-report"
            : "/academic/reportStat/studentReport";
          this.$router.push({
            path: path,
            query: {
              name: item.name,
              year: item.year,
              examDate: item.examDate,
              studentName: this.userInfo.content.name,
              subjectId: 0,
              studentId: this.userInfo.content.studentId,
              isSchoolDisplayReportStudentRank:
                item.isSchoolDisplayReportStudentRank,
              type: item.type,
              id: item.id,
              level: item.level,
              examId: item.examId,
              examType: item.examType,
              ruleId: item.ruleId,
              schoolId:
                item.examType == 1 ? getStore({ name: "school_id" }) : null,
            },
          });
        } else {
          this.$store.dispatch("report/getRoleTypeArr", item.id).then((res) => {
            sessionStorage.setItem("accountRole", JSON.stringify(res));
            this.routerTo("/academic/reportStat/reportDetail", item);
          });
        }
      } else {
        if (this.roleId == 4) {
          const isNewExam = item.scoreType == 1 && item.ruleId > 0;
          const path = isNewExam
            ? "/academic/new-exam/student-report"
            : "/academic/reportStat/studentReport";
          this.$router.push({
            path: path,
            query: {
              name: item.name,
              year: item.year,
              examDate: item.examDate,
              studentName: this.userInfo.content.name,
              subjectId: 0,
              studentId: this.userInfo.content.studentId,
              type: item.type,
              id: item.id,
              level: item.level,
              examId: item.examId,
              isUnionSubjectUser: item.isUnionSubjectUser,
              examType: item.examType,
              isSchoolDisplayReportStudentRank:
                item.isSchoolDisplayReportStudentRank,
              ruleId: item.ruleId,
              schoolId:
                item.examType == 1 ? getStore({ name: "school_id" }) : null,
            },
          });
        } else {
          this.$store.dispatch("report/getRoleTypeArr", item.id).then((res) => {
            sessionStorage.setItem("accountRole", JSON.stringify(res));
            this.routerTo("/academic/reportStat/reportDetail", item);
          });
        }
      }
    },
    getExamType(type) {
      const data = examTypeOptions.find((item) => item.value == type);
      if (data && data.label) {
        return data.label;
      }
    },
    getFilterValue(val) {
      this.search = { current: 1, size: 10, ...val };
      this.getData();
      sessionStorage.setItem(this.$route.path, JSON.stringify(this.search));
    },
    async getSchoolYearList() {
      const {
        data: { data: list },
      } = await getGradeList();
      this.yearMiddleOptions = list.filter((item) => item.level === 2);
      this.yearHignOptions = list.filter((item) => item.level === 3);
    },
    async getData() {
      this.loading = true;
      const res = await reportpage(this.search);
      this.total = res.data.data.total;
      this.list = res.data.data.records;
      this.loading = false;
    },
    sizeChangeHandle(val) {
      this.search.size = val;
      this.getData();
    },
    currentChangeHandle(val) {
      this.search.current = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.reportStat {
  .top-dashboard {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 1200px;

    &-item {
      flex: 1;
      height: 146px;
      background-color: #fff;
      margin-top: 24px;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 70px;
        height: 70px;
        margin-right: 24px;

        img {
          object-fit: contain;
        }
      }

      .data {
        .num {
          font-size: 24px;
          color: #101011;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .title {
          color: #a7a7a7;
          font-style: 14px;
          text-align: left;
        }
      }
    }

    & :last-child {
      margin-right: 0;
    }
  }

  .content {
    width: 1200px;
    margin: 20px auto;
  }

  .timeLine {
    margin-top: 50px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-content {
        display: flex;
        align-items: flex-start;
        flex: 1;

        .time {
          color: #080a09;
          font-size: 14px;
        }

        .dotted {
          width: 16px;
          height: 16px;
          border: 1px solid #3f87f4;
          border-radius: 50%;
          margin-left: 40px;
          position: relative;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #3f87f4;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .msg {
          box-sizing: border-box;
          padding-left: 40px;
          position: relative;
          flex: 1;

          &::after {
            width: 1px;
            height: 80%;
            border-left: 3px dashed #d8d8d8;
            content: "";
            position: absolute;
            top: 12%;
            left: -9px;
          }

          .title {
            color: #080a09;
            font-size: 16px;
            text-align: left;
            margin-bottom: 24px;
          }

          .operation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .tags {
              display: flex;
              align-items: center;

              .security1 {
                height: 26px;
                margin-right: 8px;
                box-sizing: border-box;
                padding: 0 12px;
                line-height: 26px;
                color: #fff;
                background-color: #62a8eb;
              }

              .security2 {
                height: 26px;
                margin-right: 8px;
                box-sizing: border-box;
                padding: 0 12px;
                line-height: 26px;
                color: #fff;
                background-color: #f24c4b;
              }

              .tag {
                margin-right: 8px;
                height: 26px;
                box-sizing: border-box;
                padding: 0 12px;
                line-height: 26px;
                font-size: 14px;
                border: 1px solid;
              }

              .time {
                color: #62a8eb;
                border-color: #62a8eb;
              }

              .other {
                color: #3f87f4;
                border-color: #3f87f4;
              }

              .general {
                color: #ffaf6a;
                border-color: #ffaf6a;
              }

              .examType1 {
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #ff8540;
                color: #ff8540;
                font-size: 14px;
              }

              .examType2 {
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #3f87f4;
                font-size: 14px;
                color: #3f87f4;
              }

              .examType3 {
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #24ca9f;
                color: #24ca9f;
                font-size: 14px;
              }
            }
          }

          .subjects {
            color: #676b70;
            font-size: 14px;
            margin-bottom: 60px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.newExam {
  background-color: #ffaf6a;
  color: #fff;
  border-color: #ffaf6a;
}

.schools {
  .filter {
    margin-bottom: 18px;
  }

  .schoolList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .school {
      margin-bottom: 18px;
      color: #2474ed;
      cursor: pointer;
    }
  }
}
</style>
